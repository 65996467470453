export function validateEmail(email: string | null): boolean {
    // tslint:disable-next-line
    const emailExpression: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( !email) {
        return true;
    }

    return Boolean(email.match(emailExpression));
}

export function validateEmail2(rule: any, email: string, callback: any) {
    // tslint:disable-next-line
    const emailExpression: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email) {
        if (Boolean(email.match(emailExpression))) {
            callback();
        } else {
            callback(new Error(rule.message));
        }
    }

    callback();
}
