




















































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import Field from '@/components/Field.vue';
import {validateEmail2} from '@/utils/ValidateEmail';
import { UserInterface } from '@/modules/user/domain/UserInterface';
import { Role } from '@/modules/user/domain/Role';
import { UserService } from '@/modules/user/service/UserService';
import { PortalZone } from '../../portal/domain/PortalZone';
import PortalZoneTransfer from '@/modules/portal/components/PortalZoneTransfer.vue';
import { Portal } from '@/modules/portal/domain/Portal';

export interface UserSubmitEvent {
    email: string;
    firstName: string | null;
    lastName: string | null;
    enabled: boolean;
    username: string | null;
    credentials: [{}] | null;
}

export interface RoleSubmitEvent {
    post: Role[] | [];
    delete: Role[] | [];
}

export interface ZoneSubmitEvent {
    id: string | null;
    zones: PortalZone[] | [];
}

@Component({
    components: {Field, PortalZoneTransfer},
})
export default class UserForm extends Vue {
    @Prop() private portals!: Portal[];
    private validateEmail = validateEmail2;
    private password: string | string = '';
    private roles: Role[] | Role[] = [];
    private selectedZones: PortalZone[] | PortalZone[] = [];
    private isLoadingZones: boolean = false;

    public rules =  {
        firstName: [
            { required: true, message: 'Le champs prénom est requis!', trigger: 'blur' },
        ],
        lastName: [
            { required: true, message: 'Le champs prénom est requis!', trigger: 'blur' },
        ],
        username: [
            { required: true, message: 'Le champs username est requis!', trigger: 'blur' },
        ],
        role: [
            { required: true, message: 'Le champs role est requis!', trigger: 'change' },
        ],
        email: [
            { required: true, message: 'Le champs courriel est requis!', trigger: 'blur' },
            {
                validator: this.validateEmail,
                message: 'Le courriel est invalide',
                trigger: 'blur',
            },
        ],
    };

    @Prop({default: {id: '',
            firstName: '',
            lastName: '',
            enabled: true,
            email: '',
            username: '',
            role: '',
            zones: []} }) private readonly user!: UserInterface;

    private async mounted() {
        await this.fetchRole();
    }

    private async fetchRole() {
        this.roles = await UserService.getKeycloakRoles(this.$store.getters['sessionStore/token']);
    }

    @Watch('user')
    private onLoadUser(user: UserInterface) {
        this.setZonesAs(user.zones);
    }

    private submit() {
        const form: any = this.$refs.userForm;
        form.validate((valid: boolean) => {
            if (valid) {
                const userSubmitEvent: UserSubmitEvent = {
                    email: this.user.email,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    enabled: this.user.enabled,
                    username: this.user.username,
                    credentials: null,
                };

                if (this.isNotEmptyPassword) {
                    userSubmitEvent.credentials = [{
                        type: 'password',
                        temporary: false,
                        value: this.password,
                    }];
                }

                const roleSubmitEvent: RoleSubmitEvent = {
                    post: [this.roles.filter((r) => r.id === this.user.role)[0]],
                    delete: [this.roles.filter((r) => r.id !== this.user.role)[0]],
                };

                const zoneSubmitEvent: ZoneSubmitEvent = {
                    id: this.user.id,
                    zones: this.selectedZones,
                };

                this.$emit('submit', userSubmitEvent, roleSubmitEvent, zoneSubmitEvent);

                if (this.user.id === '') {
                    form.resetFields();
                    const password: any = this.$refs.password;
                    password.clear();
                    const transfer: any = this.$refs.transfer;
                    transfer.$children[0].value.splice(0, transfer.$children[0].value.length);
                }
            } else {
                return false;
            }
        });
    }

    get isNotEmptyPassword() {
        return Boolean(this.password);
    }

    get buttonIcon() {
        return this.user.id !== '' ? 'el-icon-edit' : 'el-icon-add';
    }

    get buttonText() {
        return this.user.id !== '' ? 'Modifier' : 'Ajouter';
    }

    get buttonType() {
        return this.user.id !== '' ? 'warning' : 'success';
    }

    private setZonesAs(zones: PortalZone[]) {
        this.selectedZones = zones;
    }

    private onZonesUpdate(zones: PortalZone[], portalId: string) {
        const filterSelectedZones = this.selectedZones.filter((s) => s.portalId !== portalId);
        this.setZonesAs(filterSelectedZones.concat(zones));
    }
}
