








import { Vue, Component } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import Page from '@/components/Page.vue';
import UserForm, { UserSubmitEvent, RoleSubmitEvent, ZoneSubmitEvent } from '@/modules/user/components/UserForm.vue';
import { Id } from '../../user/domain/Types';
import { UserService } from '../service/UserService';
import { UserInterface } from '../domain/UserInterface';
import { PortalService } from '@/modules/portal/services/PortalService';
import { Portal } from '@/modules/portal/domain/Portal';

@Component({
    components: {
        Card,
        Page,
        UserForm,
    },
})

export default class ViewUser extends Vue {
    private isLoading: boolean = false;
    private hasError: boolean = false;
    private userId: Id = '';
    private action: string = 'create';
    private user: UserInterface | UserInterface = {
        id: '',
        firstName: '',
        lastName: '',
        enabled: true,
        email: '',
        username: '',
        role: '',
        zones: [],
    };
    private portals: Portal[] | Portal[] = [];

    private async mounted() {
        if (this.$route.params.userId) {
            this.userId = this.$route.params.userId;
        }

        await this.fetchUser();
    }

    private async fetchUser() {
        this.isLoading = true;
        if (this.userId) {
            try {
                const userZones = await UserService.fetchUserZones(
                    this.userId,
                    this.$store.getters['sessionStore/token'],
                );
                const role = await UserService.getUserRolesById(
                    this.userId,
                    this.$store.getters['sessionStore/token'],
                );

                this.user = await UserService.getUserById(
                  this.userId,
                  this.$store.getters['sessionStore/token'],
                );

                this.user.zones = userZones;
                this.user.role = role;
            } catch {
                return this.$router.push('/campaign-listing');
            }
        }

        this.portals = await PortalService.getList(this.$store.getters['sessionStore/token']);
        this.isLoading = false;
    }

    private async updateUser(user: UserSubmitEvent, role: RoleSubmitEvent, zone: ZoneSubmitEvent) {
        try {
            this.isLoading = true;
            if (this.userId) {
                this.action = 'update';
                await UserService.update(this.userId, user, this.$store.getters['sessionStore/token'])
                .catch((error) => {
                    throw new Error(error);
                });

                await UserService.updateUserZones(zone, this.$store.getters['sessionStore/token'])
                    .catch((error) => {
                        throw new Error(error);
                    });
            } else {
                await UserService.insert(user, this.$store.getters['sessionStore/token'])
                .catch((error) => {
                    throw new Error(error);
                });
                const sss = await UserService.fetchUserByEmail(user.email, this.$store.getters['sessionStore/token']);
                this.userId = sss[0].id;
                zone.id = this.userId;

                await UserService.updateUserZones(zone, this.$store.getters['sessionStore/token'])
                .catch((error) => {
                    throw new Error(error);
                });

                await UserService.postGroup(this.userId,  this.$store.getters['sessionStore/token']);
            }

            await UserService.deleteRole(this.userId, role, this.$store.getters['sessionStore/token'])
            .catch((error) => {
                throw new Error(error);
            });
            await UserService.postRole(this.userId, role, this.$store.getters['sessionStore/token'])
            .catch((error) => {
                throw new Error(error);
            })
            .then(() => {
                this.$notify.success({ title: 'SUCCÈS', message: 'Les informations ont été mises à jour !' });
            }).finally(() => {
                this.isLoading = false;

                if (this.action === 'create') {
                    this.userId = '';
                }
            });

        } catch (e) {
            this.$notify.error({
                title: 'ERREUR',
                message: e.message,
            });
        }
    }
}
