import { ApiInstance } from '@/api/ApiInstance';
import { configuration } from '@/config';
import { AuthorizationHeaderBuilder } from '@/api/AuthorizationHeaderBuilder';
import { UserInterface } from '@/modules/user/domain/UserInterface';
import { Role } from '@/modules/user/domain/Role';
import { Token } from '@/modules/session/domain/Types';
// @ts-ignore
import { RoleSubmitEvent, UserSubmitEvent, ZoneSubmitEvent } from '@/modules/user/components/UserForm.vue';

export class UserService {
    private static readonly api = ApiInstance.createFor(configuration.KEYCLOAK_ADMIN_URL);
    private static readonly userApi = ApiInstance.createFor(`${configuration.GATEWAY_URL}/pubs-neo/user`);

    public static async fetchUsers(token: string) {
        const { data: data } = await this.api.get<UserInterface[]>('/groups/' + configuration.KEYCLOAK_GROUP + '/members',
            { headers: AuthorizationHeaderBuilder.buildBearerWith(token)});
        return data;
    }

    public static async fetchUserByEmail(email: string, token: string) {
        const { data: data } = await this.api.get<UserInterface[]>('/users?email=' + email,
            { headers: AuthorizationHeaderBuilder.buildBearerWith(token)});
        return data;
    }

    public static async getUserById(id: string, token: string) {
        const { data: data }  = await this.api.get<UserInterface>('/users/' + id,
            { headers: AuthorizationHeaderBuilder.buildBearerWith(token)});
        return data;
    }

    public static async getUserRolesById(id: string, token: string) {
        const role  = await this.api.get<Role>('/users/' + id + '/role-mappings',
            { headers: AuthorizationHeaderBuilder.buildBearerWith(token)});
        return Object.keys(role.data).length > 0 &&
        role.data.clientMappings.hasOwnProperty(configuration.KEYCLOAK_CLIENT_ID) ?
            role.data.clientMappings[configuration.KEYCLOAK_CLIENT_ID].mappings[0].id : null;
    }

    public static async getKeycloakRoles(token: string) {
        const { data: data }   = await this.api.get<Role[]>('/clients/' + configuration.KEYCLOAK_CLIENT + '/roles',
            { headers: AuthorizationHeaderBuilder.buildBearerWith(token)});
        return data;
    }

    public static async update(id: string, user: UserSubmitEvent, token: Token) {
        await this.api.put('/users/' + id, user, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async insert(user: UserSubmitEvent, token: Token) {
        await this.api.post('/users', user, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async deleteKeycloakUser(id: string, token: Token) {
        await this.api.delete('/users/' + id, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async delete(id: string, token: Token) {
        await this.userApi.delete('/' + id,  {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async postRole(id: string, role: RoleSubmitEvent, token: Token) {
        await this.api.post('/users/' + id + '/role-mappings/clients/' + configuration.KEYCLOAK_CLIENT, role.post,
            { headers: AuthorizationHeaderBuilder.buildBearerWith(token)});
    }

    public static async deleteRole(id: string, role: RoleSubmitEvent, token: Token) {
        await this.api.delete('/users/' + id + '/role-mappings/clients/' + configuration.KEYCLOAK_CLIENT, {
            data:  role.delete,
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async fetchUserZones(id: string, token: Token) {
        const { data: data } = await this.userApi.get('/' + id + '/zones', {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
        return data;
    }

    public static async updateUserZones(zone: ZoneSubmitEvent, token: Token) {
        await this.userApi.put('/', zone, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async postGroup(id: string, token: Token) {
        await this.api.put('/users/' + id + '/groups/' + configuration.KEYCLOAK_GROUP,
            {realm: configuration.KEYCLOAK_REALMS, userId: id, groupId: configuration.KEYCLOAK_GROUP},
            { headers: AuthorizationHeaderBuilder.buildBearerWith(token)});
    }
}
